export const ACTION = {
    PACK_LISTING: 'PACK_LISTING',
    GET_BALANCE_INFO: 'GET_BALANCE_INFO',
    CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
    MODIFY_SUBSCRIPTION: 'MODIFY_SUBSCRIPTION',
    DUNNING_RECHARGE: 'DUNNING_RECHARGE',
    GET_CURRENT_SUBSCRIPTION_INFO: 'GET_CURRENT_SUBSCRIPTION_INFO',
    QUICK_RECHARGE: 'QUICK_RECHARGE',
    QUICK_RECHARGE_BEFORE_LOGIN: 'QUICK_RECHARGE_BEFORE_LOGIN',
    REACTIVATE_SUBSCRIPTION: 'REACTIVATE_SUBSCRIPTION',
    GET_ACCOUNT_BALANCE_INFO: 'GET_ACCOUNT_BALANCE_INFO',
};


