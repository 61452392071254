import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import PropTypes from "prop-types";

import Main from "@containers/Main/index";
import bootstrap from "@config/bootup";
import { getDeviceId } from "@utils/common";
import ScrollIntoView from "@components/ScrollIntoView";
import Loader from "@common/Loader/index.js";
import SubscriptionPaymentHandlerWrapper from "@containers/SubscriptionPayment/SubscriptionPaymentHandler/SubscriptionPaymentHandlerWrapper.jsx";

import "./assets/scss/base.scss";
import store from "./store";
import VideoPlayer from "./common/ShakaPlayer/indexShaka";
import ShakaPlayer from "./common/ShakaPlayer";

bootstrap();
getDeviceId();

if (!global._babelPolyfill) {
  require("babel-polyfill");
}
const Root = ({ store }) => (
  <Provider store={store}>
    <BrowserRouter>
      <ScrollIntoView>
        <>
          <Loader alwaysVisible={false} />
          <SubscriptionPaymentHandlerWrapper />
          <Main />
          {/* <VideoPlayer /> */}
          {/* <ShakaPlayer/> */}
        </>
      </ScrollIntoView>
    </BrowserRouter>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object,
};

// if(window.location.hostname === 'localhost' && ((window.location.pathname.split('/')[1] === "") || (window.location.pathname.split('/')[1] !=='terms-and-conditions') || (window.location.pathname.split('/')[1] !=='privacy-policy'))) {
// if(window.location.hostname === 'localhost' &&
  if(window.location.hostname === 'dev.playbinge.in' &&
// if (window.location.hostname === "web.uat-akash-go.com" &&
  // if (window.location.hostname === "akash-go.com" &&
  (window.location.pathname.split("/")[1] === "" ||
    window.location.pathname.split("/")[1] !== "terms-and-conditions") &&
  (window.location.pathname.split("/")[1] === "" ||
    window.location.pathname.split("/")[1] !== "privacy-policy") &&
  (window.location.pathname.split("/")[1] === "" ||
    window.location.pathname.split("/")[1] !== ".well-known/assetlinks.json") &&
  (window.location.pathname.split("/")[1] === "" ||
    window.location.pathname.split("/")[1] !== "help-center") && 
    (window.location.pathname.split("/")[1] === "" ||
    window.location.pathname.split("/")[1] !== ".well-known/apple-app-site-association")
) {
  document.getElementById("app2").removeAttribute("style");
  document.getElementById("app").remove();
  render(document.getElementById("app2"));
} else {
  // if((window.location.pathname.split("/")[1] !== "help-center")){
  //   document.getElementById("app2").style.display = "none";
  //   function showStuff() {
  //       document.getElementById("app2").style.display = "inline";
  //   }
  //   setTimeout(showStuff, 3000);
  // }
  document.getElementById("app2").remove();
  render(<Root store={store} />, document.getElementById("app"));
}
