import React from 'react';
// Lala -- require for api case 
import { connect } from "react-redux";	
import PropTypes from "prop-types";	
import { compose } from "redux";	
import { withRouter } from "react-router";
// end -- require for api case 


import './style.scss';

class Copyright extends React.Component {

    render() {
        console.log(this.props, "jjj");
        return (
            <div className='copyright-text'>
                {/* For Lala - JSON FILE Integration */}
                <span>{this.props.getCmsResponse?.cmsData?.web_View.CopyRight_Title}</span>
                <span>{this.props.getCmsResponse?.cmsData?.web_View.CopyRight_Desc}</span>

                {/* // Uncomment this for API Case example for cmsData api
                 <span>{this.props.getCmsResponse?.cmsData?.[0]?.brand}</span>
                <span>{this.props.getCmsResponse?.cmsData?.[0]?.description}</span> */}


                {/* <span>© Copyright 2021 by Tata Play Binge</span>
                <span>Tata ®Tata Sons Private Limited. All Sky Trademarks, and any intellectual property they contain, are owned by Sky International AG. Used under License by Tata Play Limited. Showcase ™ and © Tata Play Limited 2005. All IPR in and to the Website vests with Tata  Play Limited from 2006 onwards.
                </span> */}
            </div>
        )
    }
}

// Lala
Copyright.propTypes = {
    history: PropTypes.object,
    // Lala
     getCmsResponse:PropTypes.object
}

// Lala
const mapStateToProps = (state) => { 	
    return{         	
   
    getCmsResponse: state.fetchCmsReducer, 	
}}; 	
const mapDispatchToProps = (dispatch) => ({ 	
    getCmsDetails: (value) => dispatch(getCmsDetails(value))	
    	
});
// export default Copyright;

// Lala 
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Copyright);
