import React from "react";

// Lala
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router";
// end
// import PropTypes from "prop-types";
import { safeNavigation } from "@utils/common";
import { URL } from "@constants/routeConstants";
import "./style.scss";
import brandingLogo from "@src/Json_images/MultiChoice-logo.png";

class TataskyInfo extends React.Component {
  render() {
    //console.log(this.props.getCmsResponse?.cmsData?.brand  ,'footer')
    return (
      <div className="info-box">
        <div className="footer-text-top display-block">
          {/* Lala */}
          <p
            style={{
              margin: "20px 151px 0 -8px",
              fontSize: "1em",
              display: "inline-block",
            }}
            // className={`${hideBrandLogo && "hide-more-option"}`}
            onClick={() =>
              !sourceIsMSales && safeNavigation(history, URL.DEFAULT)
            }
          >
            {/* Tata Play binge  */}
            <img
              onClick={() =>
                safeNavigation(this.props.history, `${URL.DEFAULT}`)
              }
              className={"botmIcon"}
              alt="freemium-image"
              style={{ width: "80px", cursor: "pointer" }}
              src={brandingLogo} // case of custom Json file data
              //     src = {this.props.getCmsResponse?.cmsData?.[0]?.avatar} // dynamic API Case
            />
          </p>
          {/* END  */}
          {/* <h1
                        onClick={() => safeNavigation(this.props.history, `${URL.DEFAULT}`)}
                    > */}
          {/* Tata Play Binge Anywhere */}
          {/* <img
                         onClick={() => safeNavigation(this.props.history, `${URL.DEFAULT}`)}
                        className={"botmIcon"}
                        alt="freemium-image"
                        src={this.props.getCmsResponse?.cmsData?.[0]?.avatar} />*/}
          {/* </h1> */}
        </div>
        <div className="footer-text-bottom display-block">
          {/* <span>Connecting our viewers to the best content globally =  available on any budget, any screen and anytime, anywhere</span> */}

          {/* Lala  - Case of Dynamic api*/}
          {/* <span>{this.props.getCmsResponse?.cmsData?.[0]?.brand}</span>
                    <span>{this.props.getCmsResponse?.cmsData?.[0]?.description}</span> */}
          {/* // end of Dynamic API  */}
          {/* Lala  - Case of Custom Json file*/}
          <span>{this.props.getCmsResponse?.cmsData?.web_View?.Brand}</span>
          <span>
            {this.props.getCmsResponse?.cmsData?.web_View?.footer_Info}
          </span>
          {/* // end  - Case of Custom Json file



                    {/* <span>Tata Play Limited is a joint venture between Tata Sons and TFCF Corporation (Formerly known as Twenty-First Century Fox, Inc). Incorporated im 2001 and with services launched since 2006, Tata Play is India�s leading content distribution platform providing Pay TV and OTT services. With the objective of connecting to the best content in the world on any budget, any screen, anytime and anywhere. Tata Play was the first to launch multiple products and services that redefined the subscribers� viewing experience ion the country.</span> */}
        </div>
      </div>
    );
  }
}

TataskyInfo.propTypes = {
  history: PropTypes.object,
  // Lala
  getCmsResponse: PropTypes.object,
};

// Lala
const mapStateToProps = (state) => {
  return {
    getCmsResponse: state.fetchCmsReducer,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCmsDetails: (value) => dispatch(getCmsDetails(value)),
});
// export default TataskyInfo;

// Lala
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TataskyInfo);
