import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MovieList.scss";
import { isMobile } from "@utils/common";

const MovieListing = ({ castAndCrewData }) => {
  if (!castAndCrewData) return null;

  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    draggable: true,
    swipeToSlide: true,
    arrows: !isMobile.any(),
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const MovieCastAndCrew = ({
    profile_path,
    name,
    character,
    known_for_department,
    gender,
  }) => {
    // debugger;
    return (
      <div className="CastAndCrew">
        <div className="CastAndCrew__prifileImage">
          {profile_path && (
            <img
              src={`https://image.tmdb.org/t/p/w500/${profile_path}`}
              alt={name}
            />
          )}
        </div>
        <div className="CastAndCrew__prifileDetails">
          <h3 className="CastAndCrew__prifileDetails--name">{name}</h3>
          <h4 className="CastAndCrew__prifileDetails--kfd">
            Portrays: {character ? character : "Note Specified"}
          </h4>
          <h4 className="CastAndCrew__prifileDetails--kfd">
            know For The Dpt:
            {known_for_department ? known_for_department : "Note Specified"}
          </h4>
          <h4 className="CastAndCrew__prifileDetails--gender">
            Gender:
            {gender === 1 ? "Female" : gender === 2 ? "Male" : "Note Specified"}
          </h4>
        </div>
      </div>
    );
  };

  return (
    <div className="slider-container movieList">
      <h2 className="movieList__header">Cast & Crew</h2>
      <Slider {...settings}>
        {castAndCrewData?.map((cast) => {
          return <MovieCastAndCrew {...cast} />;
        })}
      </Slider>
    </div>
  );
};

export default MovieListing;
