import get from 'lodash/get';
import {ACTION} from '../FetchCms/constants';

let initialState = {};

export default (state = initialState, action) => {
    switch (action.type) { 
    case ACTION.GET_FETCH_CMS_DETAILS:
   return {...state, cmsData: action.apiResponse}; 
    default:
 return state;
    }
};