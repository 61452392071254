export const ACTION = {
  GET_OTP_WITH_RNM: "GET_OTP_WITH_RNM",
  GET_OUTH_TOKEN: "GET_OUTH_TOKEN",
  VALIDATE_OTP: "VALIDATE_OTP",
  SUBSCRIBER_LIST: 'SUBSCRIBER_LIST',
  CREATE_BINGE_USER: 'CREATE_NEW_USER',
  UPDATE_BINGE_USER: 'UPDATE_BINGE_USER',
  OPEN_LOGIN_POPUP: 'OPEN_LOGIN_POPUP',
  CLOSE_LOGIN_POPUP: 'CLOSE_LOGIN_POPUP',
  LOGIN_STEP_NUMBER: 'LOGIN_STEP_NUMBER',
  RESET_LOGIN_STATE: 'RESET_LOGIN_STATE',
  VALIDATE_FS_WEB_SMALL_URL: 'VALIDATE_FS_WEB_SMALL_URL',
  GET_CLIENT_IP: 'GET_CLIENT_IP',
  MANUAL_LOGIN_CHECK: "MANUAL_LOGIN_CHECK",
  SILENT_LOGIN_FAILED: 'SILENT_LOGIN_FAILED',
  ON_MANUAL_LOGIN: "ON_MANUAL_LOGIN",
  FORCE_LOGOUT: "FORCE_LOGOUT",
  SET_IS_LOGIN_POPUP_VISIBLE: "SET_IS_LOGIN_POPUP_VISIBLE",
  VALIDATE_OFS_SMART_TV_CODE: 'VALIDATE_OFS_SMART_TV_CODE',
}

export const SUBSCRIBED_MESSAGE = {
  ALREADY_SUBSCRIBED: 'Already Subscribed',
  SUCCESSFULLY_LOGGED_IN: 'Successfully logged in'
}

export const VALIDATE_SOURCE = {
  SMART_TV : 'smart-tv'
};