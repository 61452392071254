export const ACTION = {
    FETCH_WATCHLIST_ITEMS: "FETCH_WATCHLIST_ITEMS",
    CLEAR_WATCHLIST_DATA: "CLEAR_WATCHLIST_DATA",
    UPDATE_WATCHLIST_DATA: "UPDATE_WATCHLIST_DATA",
};

export const DEFAULT_VALUE = {
    LIMIT: "12",
    SELECTALL: "Select All ",
    DESELECTALL: 'Deselect All',
};