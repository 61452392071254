import React from 'react';
import {PLAY_STORE_URL} from '@constants';
import './style.scss';
import GooglePlay from "@assets/images/play-store.png"
import AppleStore from "@assets/images/apple-store.png"
import {user_Info} from "@src/jsonfile.json";
import {fireFooterClickEvent} from '@utils/common';
import DATALAYER from '@utils/constants/dataLayer';

class DownloadLink extends React.Component {
    render() {
        return (
            <div className='download-link'>
                {/* <span>Download app now</span> */}
                <span>{user_Info.Download_App_Now}</span>
                <span className={"app-store-btn"}>
                    <a href=
                   // {PLAY_STORE_URL.ANDROID} old code
                        {user_Info.DOWNLOAD_APP.GooglePlay}
                    onClick={() => fireFooterClickEvent(DATALAYER.VALUE.PLAY_STORE)}
                       target="_blank" rel="noreferrer">
                        <img 
                        src={GooglePlay} // Old code

                         className='play-store' alt="google-play-store"/>
                    </a>
                    <a href=
                    //{PLAY_STORE_URL.IOS}  // Old code
                    {user_Info.DOWNLOAD_APP.IOS}
                    target="_blank" rel="noreferrer"
                       onClick={() => fireFooterClickEvent(DATALAYER.VALUE.APP_STORE)}>
                        <img 
                        //src={AppleStore}
                        src={AppleStore} 

                        className='apple-store' alt="app-store"/>
                    </a>
                </span>
            </div>
        )
    }
}

export default DownloadLink;
