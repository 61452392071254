import ENV_CONFIG from '@config/environment/index';

export default {
  EVENT: {
    LOGIN_SUCCESS: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.LOGIN_SUCCESS,
    LOGIN_GET_OTP: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.LOGIN_GET_OTP,
    PACK_SELECTION_INITIATE: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.PACK_SELECTION_INITIATE,
    PAYMENT_INITATION_PAGE: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.PAYMENT_INITATION,
    SUBSCRIPTION_SUCCESS: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.SUBSCRIPTION_SUCCESS,
  },
  VALUE: {
    CURRENCY: "INR",
    MSALES: 'mSales'
  },
  PARAMETER: {
    VALUE: "value",
    CURRENCY: "currency",
    SOURCE: "source"
  },
  ANALYTICS: "CONVERSION",
};
